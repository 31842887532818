export declare type ApplicationEvent = {
    eventType: string,
    selector: string,
    function: (event: Event) => any;
};

enum ApplicationEventTypes {
    Click = 'click',
    Change = 'change',
    Submit = 'submit',
    Keypress = 'keypress'
}

class HasEventListeners {

    protected listeners: ApplicationEvent[] = [];

    protected addClickEvent = (selector: string, callback: (event: Event) => any): void => {
        this.addEvent(ApplicationEventTypes.Click, selector, callback);

    }

    protected addChangeEvent = (selector: string, callback: (event: Event) => any): void => {

        this.addEvent(ApplicationEventTypes.Change, selector, callback);

    }

    protected addSubmitEvent = (selector: string, callback: (event: Event) => any): void => {

        this.addEvent(ApplicationEventTypes.Submit, selector, callback);

    }

    protected addKeypressEvent = (selector: string, callback: (event: Event) => any): void => {

        this.addEvent(ApplicationEventTypes.Keypress, selector, callback);

    }

    protected addEvent = (eventType: string, selector: string, callback: (event: Event) => any): void => {

        const event: ApplicationEvent = {
            eventType: eventType,
            selector: selector,
            function: callback
        };

        this.listeners.push(event);

        this.registerEvent(event)

    }

    protected registerEvents = (): void => {

        this.listeners.forEach((event) => {
            this.registerEvent(event)
        });

    }

    private registerEvent = (event: ApplicationEvent): void => {

        const self = this;

        $(document).on(
            event.eventType,
            event.selector,
            event.function.bind(self)
        );

    }

    public getEventTarget = <T = HTMLElement>(e: any, cssTargetSelector: string): T => {

        const targetSelector = cssTargetSelector.substring(1);

        const clickedEl: HTMLElement = e.target;

        return clickedEl.classList.contains(targetSelector)
            ? clickedEl as unknown as T
            : clickedEl.closest(cssTargetSelector) as unknown as T;
    }
}

export default HasEventListeners;