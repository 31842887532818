"use strict";

import HasEventListeners from "Utils/HasEventListeners";
import {LoaderComponent} from "Components/global/loader.component";
import './base.controller.scss'

class BaseController extends HasEventListeners {}

export default BaseController;

(async () => {
    LoaderComponent.hidePageLoader();
    LoaderComponent.hideAsyncLoader();
})();
