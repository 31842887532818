"use strict";

import BaseController from "Controllers/base.controller";
import {AjaxRequest, RequestParameters} from "UtilTypes/ajaxRequest";
import {UtilServicesHelper} from "UtilServices";
import {AjaxEndPoints} from "Request/ajaxEndPoints";
import {loginCheck} from "Response/response";
import {AjaxResponse} from "UtilTypes/ajaxResponse";
import {LoaderComponent} from "Components/global/loader.component";
import './login.controller.scss'

declare var grecaptcha: any;

class LoginController extends BaseController
{

    private readonly childElements = {
        usernameField: '#form_login_username',
        passwordField: '#form_login_password',
        submitButton: '#form_login_submit',
        loginForm: '#login_form',
        loginFormWrapper: '#js_login_form_wrapper',
        authenticationErrorModalWrapper: '#js-modal-authentication-errors-wrapper',
        authenticationErrorModal: '#js-modal-authentication-errors',
        captchaError: '.js-captcha-error'
    };


    public init = async (): Promise<void> =>
    {

        this.addClickEvent(this.childElements.submitButton, this._login);
        this.addKeypressEvent(null, this._getKeyPressed);
        this.addSubmitEvent(this.childElements.loginForm, this._stopAllEvents);

    }

    private resetCaptcha(): void {

        grecaptcha.reset();

    }

    private _login($event: Event): void
    {
        $event.preventDefault();

        const self = this;

        $(self.childElements.submitButton).prop('disabled', true);

        let form = $(this.childElements.loginForm)[0] as HTMLFormElement;

        let requestParameters: RequestParameters = {
            method:UtilServicesHelper.ajaxMethodType.POST,
            url: AjaxEndPoints.ajaxLoginCheck.nodo,
            data: UtilServicesHelper.createFormDataFromHtmlElement(form),
            cache: false,
            contentType: false,
            processData: false
        };

        let ajaxRequest: AjaxRequest = {
            requestParameters: requestParameters,
            showAjaxLoader: true, //Set this to true, if you need a loader
            hideAjaxLoaderAfterRequest: false,
            ajaxCallbackFunction: null
        };

        UtilServicesHelper.deferredAjaxRequest<loginCheck>(ajaxRequest)
            .then( (response: AjaxResponse<loginCheck>) => {

                self._handleAjaxResponse(response).then( () => {

                },() => {
                    $(self.childElements.submitButton).prop('disabled', false);
                });

            }).catch( () => {
            $(self.childElements.submitButton).prop('disabled', false);
        })
    }

    /**
     *
     * @param form
     * @private
     */
    private _showResponseFormWithErrors(form:string)
    {
        $(this.childElements.loginFormWrapper).html(form);
    }

    /**
     *
     * @param $template
     * @param $elementWrapper
     * @protected
     */
    protected _appendToModal($template: string, $elementWrapper: string): void
    {
        $(document).find($elementWrapper).html($template);

    }

    /**
     *
     * @param $selector
     * @protected
     */
    protected _showResponseAuthenticationErrors($selector: string): void
    {

        $(document).find($selector).modal();

    }

    /**
     *
     * @param response
     * @private
     */
    private async _handleAjaxResponse(response: AjaxResponse<loginCheck>)
    {
        const self = this;


        if ( response.statusCode !== 200 ) {

            this.resetCaptcha();

        }

        switch (response.statusCode)
        {
            case 200:
            {
                LoaderComponent.showAsyncLoader();
                UtilServicesHelper.goToPage(response.response.url);
                break;
            }
            case 400:
            {
                await self._showResponseFormWithErrors(response.response);
                $(self.childElements.submitButton).prop('disabled', false);
                break;
            }
            case 401: //Captcha Error
            {
                $(self.childElements.submitButton).prop('disabled', false);
                $(self.childElements.captchaError).text('Captcha non valido!');
                break;
            }
            case 412:
            {
                self._appendToModal(response.response, self.childElements.authenticationErrorModalWrapper)
                self._showResponseAuthenticationErrors(self.childElements.authenticationErrorModal);
                $(self.childElements.submitButton).prop('disabled', false);
                break;
            }
            case 413:
            {
                self._appendToModal(response.response, self.childElements.authenticationErrorModalWrapper)
                self._showResponseAuthenticationErrors(self.childElements.authenticationErrorModal);
                $(self.childElements.submitButton).prop('disabled', false);
                break;
            }
            default:
                $(self.childElements.submitButton).prop('disabled', false);
                break;
        }

    }

    /**
     *
     * @param $event
     * @private
     */
    private _getKeyPressed($event: any): void
    {
        let keyCode = ($event.keyCode ? $event.keyCode : $event.which);

        if(keyCode == '13')
        {
            // noinspection JSDeprecatedSymbols
            $(this.childElements.submitButton).click();
        }
        else
        {
            return;
        }

    }

    /**
     * Stop all events
     *
     * @param $event
     * @protected
     */
    protected _stopAllEvents($event: Event): void
    {
        $event.preventDefault();
        $event.stopPropagation();
        $event.stopImmediatePropagation();
    }

}

export default LoginController;