import Swal, {SweetAlertIcon} from "sweetalert2";
import router from "Router";
import {AjaxRequest, AjaxRequestMethodType} from "UtilTypes/ajaxRequest";
import {AjaxResponse} from "UtilTypes/ajaxResponse";
import {LoaderComponent} from "Components/global/loader.component";

export type Blobs = "primary" | "secondary" | "tertiary";

export class UtilServicesHelper {

    static readonly Routing = router;

    // private static readonly Dictionary: GlobalDictionary = {
    //     it: italianDictionary,
    //     de: germanDictionary,
    //     pt: portugueseDictionary,
    //     en: englishDictionary,
    //     es: spanishDictionary,
    //     du: dutchDictionary,
    //     fr: frenchDictionary
    // }

    static readonly HTTP_CUSTOM_CODES = {
        VALIDATION_ERROR: 699,
        DUPLICATION_ERROR: 698,
        USERNAME_NOT_FOUND_ERROR: 711,
        WRONG_CREDENTIALS_ERROR: 712,
        USER_ALREADY_REGISTERED_ERROR: 400,
        RECOVER_USER_NOT_FOUND_ERROR: 404,
        CHANGE_PASSWORD_WRONG_USERNAME_ERROR: 404,
        CHANGE_PASSWORD_WRONG_OLD_ERROR: 409,
        WRONG_AUTHENTICATION_CHECK_CODE: 713,
        HTTP_STATUS_CONFLICT: 409,
        PRE_REGISTER_CODE_ALREADY_USED: 409,
        PRE_REGISTER_CODE_NOT_FOUND: 404,
        MODULE_MISSIONS_USER_ALREADY_REGISTERED: 404,
        TICKET_ALREADY_OPENED: 503,

        CUSTOM_VALIDATION_ERROR: 999,
        CUSTOM_EMPTY_QUIZ_ERROR: 998,
        CUSTOM_VALIDATION_ERROR_REGION: 997,
        UNAUTHORIZED: 401,
        NOT_FOUND: 404,
    };

    static readonly type: { success: SweetAlertIcon, error: SweetAlertIcon, info: SweetAlertIcon, warning: SweetAlertIcon } = {
        success: 'success',
        error: 'error',
        info: 'info',
        warning: "warning"
    };

    static readonly ajaxMethodType: AjaxRequestMethodType = {
        POST: 'POST',
        GET: 'GET',
        PUT: 'PUT',
        DELETE: 'DELETE'
    };

    /**
     *
     * @param ajaxRequest
     */
    static deferredAjaxRequest<RES>(ajaxRequest: AjaxRequest): JQuery.Promise<AjaxResponse<RES>> {

        let deferred = $.Deferred();

        if (ajaxRequest.showAjaxLoader) {
            LoaderComponent.showAsyncLoader();
        }

        $.ajaxSetup({
            beforeSend: function (xhr, settings) {
                xhr.setRequestHeader("X-Requested-With", "XMLHttpRequest");
                xhr.setRequestHeader("Access-Control-Allow-Origin", "*");
            }
        });

        $.ajax(ajaxRequest.requestParameters)
            .then((response) => {

                LoaderComponent.hideAsyncLoader();

                let ajaxResponse: AjaxResponse<RES> = response;

                deferred.resolve(ajaxResponse);

            }, (reject) => {

                LoaderComponent.hideAsyncLoader();

                deferred.reject(reject);
            });

        return deferred.promise();
    }

    static createFormDataFromHtmlElement(form: HTMLFormElement): FormData {
        return new FormData(form);
    }

    static goToPage(router: string): void {
        window.location.href = router;
    }

    static reloadPage() {
        window.location.reload();
    }

    static redirect = (path: string, newTab: boolean = false) => {
        console.log('redirect to', path);
        LoaderComponent.showAsyncLoader();

        if (newTab) {
            window.open(path, '_blank').focus();
            return;
        }

        window.location.href = path;
        return;
    }
    /**
     * This function shows a swal info alert.
     *
     * @param {string} title - The message title.
     * @param {string} text - The message.
     * @param confirmButtonText
     * @param showCancelButton
     * @param cancelButtonText
     * @param {Function} onConfirm
     * @param {Function} onReject
     * @param {Function} onSuccess
     * @param {Function} onError
     */
    public static showInfoSwalAlert(
        title: string,
        text: string,
        showCancelButton: boolean = false,
        confirmButtonText: string = 'Sì',
        cancelButtonText: string = 'No',
        onConfirm: (...args: any[]) => any = null,
        onReject: (...args: any[]) => any = null,
        onSuccess: (...args: any[]) => any = null,
        onError: (...args: any[]) => any = null,
    ): void {
        let self = this;

        Swal.fire({
            title: title,
            html: text,
            icon: self.type.info,
            showCancelButton: showCancelButton,
            confirmButtonText: confirmButtonText,
            cancelButtonText: showCancelButton ? cancelButtonText : '',
            confirmButtonColor: "#19AEB8",
        }).then((result) => {

            if (result.value) {
                if (typeof onConfirm !== 'undefined' && onConfirm !== null)
                    onConfirm();

            } else if (result.dismiss === Swal.DismissReason.cancel) {
                if (typeof onReject !== 'undefined' && onReject !== null)
                    onReject();
            }
        })
    }

    /**
     * This function shows a swal error alert.
     *
     * @param {string} title - The message title.
     * @param {string} text - The message.
     * @param confirmButtonText
     * @param showCancelButton
     * @param allowOutsideClick
     * @param cancelButtonText
     * @param {Function} onConfirm
     * @param {Function} onReject
     * @param {Function} onSuccess
     * @param {Function} onError
     */
    public static showErrorSwalAlert(
        title: string,
        text: string,
        showCancelButton: boolean = false,
        confirmButtonText: string = 'Sì',
        cancelButtonText: string = 'No',
        onConfirm: (...args: any[]) => any = null,
        onReject: (...args: any[]) => any = null,
        onSuccess: (...args: any[]) => any = null,
        onError: (...args: any[]) => any = null,
        allowOutsideClick: boolean = true,
    ): void {
        let self = this;

        Swal.fire({
            title: title,
            html: text,
            icon: self.type.error,
            showCancelButton: showCancelButton,
            allowOutsideClick: allowOutsideClick,
            confirmButtonText: confirmButtonText,
            cancelButtonText: showCancelButton ? cancelButtonText : '',
            confirmButtonColor: "#19AEB8",
        }).then((result) => {

            if (result.value) {
                if (typeof onConfirm !== 'undefined' && onConfirm !== null)
                    onConfirm();

            } else if (result.dismiss === Swal.DismissReason.cancel) {
                if (typeof onReject !== 'undefined' && onReject !== null)
                    onReject();
            }
        })
    }

    /**
     * This function shows a swal info alert.
     *
     * @param {string} title - The message title.
     * @param {string} text - The message.
     * @param confirmButtonText
     * @param showCancelButton
     * @param cancelButtonText
     * @param {Function} onConfirm
     * @param {Function} onReject
     * @param {Function} onSuccess
     * @param {Function} onError
     */
    public static showWarningSwalAlert(
        title: string,
        text: string,
        showCancelButton: boolean = false,
        confirmButtonText: string = 'Sì',
        cancelButtonText: string = 'No',
        onConfirm: (...args: any[]) => any = null,
        onReject: (...args: any[]) => any = null,
        onSuccess: (...args: any[]) => any = null,
        onError: (...args: any[]) => any = null,
    ): void {
        let self = this;

        Swal.fire({
            title: title,
            html: text,
            icon: self.type.warning,
            showCancelButton: showCancelButton,
            confirmButtonText: confirmButtonText,
            cancelButtonText: showCancelButton ? cancelButtonText : '',
            confirmButtonColor: "#19AEB8",
        }).then((result) => {

            if (result.value) {
                if (typeof onConfirm !== 'undefined' && onConfirm !== null)
                    onConfirm();

            } else if (result.dismiss === Swal.DismissReason.cancel) {
                if (typeof onReject !== 'undefined' && onReject !== null)
                    onReject();
            }
        })

    }

    /**
     * This function shows a success info alert.
     *
     * @param {string} title - The message title.
     * @param {string} text - The message.
     * @param confirmButtonText
     * @param showCancelButton
     * @param cancelButtonText
     * @param {Function} onConfirm
     * @param {Function} onReject
     * @param {Function} onSuccess
     * @param {Function} onError
     */
    public static showSuccessSwalAlert(
        title: string,
        text: string,
        showCancelButton: boolean = false,
        confirmButtonText: string = 'Sì',
        cancelButtonText: string = 'No',
        onConfirm: (...args: any[]) => any = null,
        onReject: (...args: any[]) => any = null,
        onSuccess: (...args: any[]) => any = null,
        onError: (...args: any[]) => any = null,
    ): void {
        let self = this;

        Swal.fire({
            title: title,
            html: text,
            icon: self.type.success,
            showCancelButton: showCancelButton,
            confirmButtonText: confirmButtonText,
            cancelButtonText: showCancelButton ? cancelButtonText : '',
            confirmButtonColor: "#19AEB8",
        }).then((result) => {

            if (result.value) {
                if (typeof onConfirm !== 'undefined' && onConfirm !== null)
                    onConfirm();

            } else if (result.dismiss === Swal.DismissReason.cancel) {
                if (typeof onReject !== 'undefined' && onReject !== null)
                    onReject();
            }

        });

    }

    public static stickyNav(): void {

        const body = document.getElementsByTagName('body')[0];
        const header = document.getElementById('siteHeader');
        const sticky = header.offsetTop;

        UtilServicesHelper.calcStickyNav(body, header, sticky);

        window.onscroll = function () {
            UtilServicesHelper.calcStickyNav(body, header, sticky);
        };

    }

    public static calcStickyNav(body, header, sticky) {

        const threshold = window.pageYOffset - 80;

        if (threshold > sticky) {
            header.classList.add('stuck');
            body.classList.add('stuck-header');
        } else {
            header.classList.remove('stuck');
            body.classList.remove('stuck-header');
        }

    }

    public static createProgressbar(id, duration, callback) {

        var progressbar = document.getElementById(id);
        progressbar.className = 'progressbar';

        var progressbarinner = document.createElement('div');
        progressbarinner.className = 'inner';

        // Now we set the animation parameters
        progressbarinner.style.animationDuration = `${duration}s`;

        // Eventually couple a callback
        if (typeof(callback) === 'function') {
            progressbarinner.addEventListener('animationend', callback);
        }

        progressbar.appendChild(progressbarinner);

        progressbarinner.style.animationPlayState = 'running';
    }

    public static addBlobs(blobs: Blobs[]): void {

        const $wrapper = $('.blobs-wrapper');

        for (let i = 0; i < blobs.length; i++) {

            $wrapper.append(`
                <div class="blob-wrapper blob-wrapper--${blobs[i]}">
                    <div class="blob"></div>
                </div>
            `);

        }

        return;

    }

    public static objectToFormData = (object: {}, removeUndefinedValues: boolean = true): FormData => {

        if (!object)
            return new FormData();

        return Object.keys(object).reduce((formData, key) => {

            const current = object[key];

            if (removeUndefinedValues) {
                if (current !== undefined && typeof current !== 'undefined') {
                    if (Array.isArray(current)) {
                        for (let i = 0; i < current.length; i++) {
                            formData.append(`${key}[]`, current[i]);
                        }
                    } else {
                        formData.append(key, current);
                    }
                }
            } else {
                if (Array.isArray(current)) {
                    for (let i = 0; i < current.length; i++) {
                        formData.append(`${key}[]`, current[i]);
                    }
                } else {
                    formData.append(key, current);
                }
            }
            return formData;
        }, new FormData());

    }

    public static stringToHtml(string: string) {

        const nodes = new DOMParser().parseFromString(string, 'text/html').body.childNodes;

        return nodes[0];

    }

}